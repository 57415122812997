.counter {
  padding: var(--spacing-2) 0 0;
  float: right;
  font-size: 12px;
  color: var(--color-neutral-500);
}

.countLength {
  display: inline;
  color: var(--color-neutral-700);
}

.countLengthError {
  color: var(--color-error-500);
}

.hideMobile {
  @media (--mobile) {
    display: none;
  }
}
