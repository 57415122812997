.textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  resize: none;
  -webkit-appearance: none;
  line-height: inherit;
  color: var(--textarea-color);
  caret-color: var(--textarea-caret-color);
  min-height: var(--form-textarea-min-height);
}

.textarea:-webkit-autofill {
  -webkit-text-fill-color: var(--textarea-color);
}

.textarea::placeholder {
  color: var(--textarea-placeholder-color);
}

.textarea:disabled {
  color: var(--textarea-disabled-color);
  -webkit-text-fill-color: var(--textarea-disabled-color);
  cursor: var(--disabled-cursor);
  opacity: 1;
}

.textarea:read-only {
  color: var(--textarea-disabled-color);

  &:focus::placeholder {
    color: var(--textarea-disabled-color);
  }
}

.formControl {
  --form-control-input-padding: var(--spacing-4);
  --form-control-hint-fint-size: 12px;
}

& [data-tenant='rac'] {
  & .textarea {
    font-weight: 200;
  }
}
